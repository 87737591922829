<template>
    <!-- 用户信息 -->
    <div class="package_Index">
        <el-row>
            <el-col :span="24" style="margin-top: 0px">
                <!-- <el-form
                        size="small"
                        :inline="true"
                        :model="formInline"
                        class="demo-form-inline"
                        style="display: flex; justify-content: space-between"
                >
                    <el-col :span="16">
                        <el-form-item label="名称">
                            <el-input v-model="formInline.input" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select style="width: 120px" v-model="formInline.user" placeholder="职位状态">
                                <el-option label="所有" value="2"></el-option>
                                <el-option label="停用" value="0"></el-option>
                                <el-option label="正常" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-button style="margin-left: 10px" @click="init()" type="primary" size="small"
                        >查询</el-button
                        >
                    </el-col>
                    <el-col :span="4" style="display: flex; justify-content: space-between">
                        <span></span> -->
                <!--                        :disabled="!permissionsAction.add"-->
                <!-- 
                        <el-button
                                style="height: 32px !important"
                                type="primary"
                                size="small"
                                @click="addPackage()"
                                class="koaz"
                        >新建</el-button
                        >
                    </el-col>
                </el-form> -->
            </el-col>
            <div style="width: 100%; margin-top: 20px">
                <el-table
                    :header-cell-style="{ background: '#F2F2F2' }"
                    :data="tableData"
                    border
                    style="width: 100%; text-align: center"
                >
                    <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
                    <el-table-column width="200" align="center" prop="module_name" label="模块名称"></el-table-column>
                    <el-table-column align="center" prop="billing_unit" label="计费单位">
                        <template #default="scope">
                            <div v-if="scope.row.billing_unit == 1">天</div>
                            <div v-if="scope.row.billing_unit == 2">个</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>
                    <el-table-column width="150" align="center" prop="p_isenable" label="状态">
                        <template #default="scope">
                            <div v-if="scope.row.status == 1">启用</div>
                            <div v-else>停用</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template #default="scope">
                            <!--                            :disabled="!permissionsAction.edit"-->
                            <el-button @click="editClick(scope.row)" type="text" size="small">配置</el-button>
                            <!--                            :disabled="!permissionsAction.enable"-->
                            <el-button @click="disableClick(scope.row)" type="text" size="small">{{
                                scope.row.status == 1 ? '停用' : '启用'
                            }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: center; margin-top: 25px; background-color: #f9f9f9">
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.page"
                        :page-sizes="[10, 15, 30, 35]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="page.totalItems"
                    >
                    </el-pagination>
                </div>

                <el-dialog v-model="dialogVisible" title="资费配置" width="420px" center :before-close="handleClose">
                    <span>
                        <el-form
                            size="small"
                            :model="ruleForm"
                            ref="ruleForm"
                            label-position="right"
                            label-width="120px"
                        >
                            <el-form-item label="模块名称：" prop="name">
                                <div>{{ ruleForm.name }}</div>
                            </el-form-item>
                            <el-form-item class="specsInput" label="模块费用：" prop="price">
                                <el-input
                                    v-model="ruleForm.price"
                                    class="specsInput1"
                                    style="width: 180px"
                                    suffix-icon="el-icon-search"
                                    type="number"
                                ></el-input>
                                元/{{ unit }}
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="sendClick" size="small" type="primary">确定</el-button>
                                <el-button @click="handleClose" size="small">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </span>
                </el-dialog>
            </div>
        </el-row>
    </div>
</template>
<script>
import { index_postage, package_edit } from '../../../common/js/package';
import { update_package, idsort_package } from '../../../common/js/package';
export default {
    name: 'postage_index',
    components: {},
    data() {
        return {
            dialogVisible: false,
            formInline: {
                user: '2',
                input: ''
            },
            packageSort: [],
            // 表格数据
            tableData: [],
            // 分页
            page: {
                page: 1, // 当前页
                pageSize: 10,
                totalItems: 0
            },
            // 权限操作集合
            permissionsAction: {},
            ruleForm: {
                id: '',
                name: '',
                price: ''
            }
        };
    },
    created() {
        this.init();
        this.getMsg();
    },
    methods: {
        addPackage() {
            this.$emit('changeStatus', 2);
        },
        sendClick() {
            if (!this.ruleForm.id) {
                this.$message.error('数据异常，请刷新页面后重试');
                return;
            }
            if (!this.ruleForm.price) {
                this.$message.error('请输入模块费用');
                return;
            }
            let dt = {
                id: this.ruleForm.id,
                billing_cost: this.ruleForm.price
            };
            package_edit(dt).then(res => {
                this.$message.success(res.msg);
                this.handleClose();
            });
        },
        handleClose() {
            this.$refs.ruleForm.resetFields();
            this.dialogVisible = false;
            this.init();
        },
        // 停用套餐
        disableClick(row) {
            let dt = {
                id: row.id,
                status: row.status == 1 ? 0 : 1
            };
            update_package(dt).then(res => {
                this.$message.success(res.msg);
                this.init();
            });
        },
        // 新建/编辑套餐
        editClick(row) {
            this.ruleForm = {
                id: row.id,
                name: row.module_name,
                price: row.billing_cost
            };
            this.unit = row.billing_unit == 1 ? '天' : '个';
            this.dialogVisible = true;
        },
        // 分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.page.page = 1;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        },
        init() {
            this.dt = {
                page: this.page.page,
                size: this.page.pageSize
            };
            index_postage(this.dt).then(res => {
                this.tableData = res.result.data;
                // console.log(res.result);
                // let { Add: add, Edit: edit, Enable: enable, Send: send } = res.result.Power;
                // this.permissionsAction = {
                //     add,
                //     edit,
                //     enable,
                //     send
                // };
                this.page.totalItems = res.result.total;
            });
        },
        getMsg() {
            idsort_package().then(res => {
                let { code, result } = res;
                if (code == 200) {
                    this.packageSort = result;
                }
            });
        }
    }
};
</script>
<style>
.package_Index .specsInput .el-icon-search:before {
    display: none;
}

/* .package_Index .specsInput1 .el-input__suffix-inner .el-input__icon:after {
        content: '元/天' !important;
        width: 80px;
        margin-left: -40px;
    } */
</style>
