<template>
    <div>
        <Postage v-if="status == 1" @changeStatus="changeStatus"></Postage>
        <!-- <EditPackage v-if="status == 2" @changeStatus="changeStatus"></EditPackage> -->
    </div>
</template>
<script>
// import EditPackage from "./components/EditPackage";
import Postage from './components/postage_index.vue';
export default {
    components: {
        // PackageIndex,
        // EditPackage,
        Postage
    },
    data() {
        return {
            status:1,
        };
    },
    created() {

    },
    methods: {
        changeStatus(val){
            this.status = val;
        }
    }
};
</script>
<style>

</style>